@font-face {
    font-family: "Galvji Regular";
    src: url("galvji-01-webfont.woff2") format("woff2"),
        url("galvji-01-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Galvji Bold";
    src: url("galvji-bold-02-webfont.woff2") format("woff2"),
        url("galvji-bold-02-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Galvji Bold_oblique";
    src: url("galvji-boldoblique-04-webfont.woff2") format("woff2"),
        url("galvji-boldoblique-04-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Galvji Oblique";
    src: url("galvji-oblique-03-webfont.woff2") format("woff2"),
        url("galvji-oblique-03-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}