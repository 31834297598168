.event-calender-sec {
    padding: 0 15px 15px;
  
    .calendar-container {
      .rbc-time-content {
        .rbc-time-slot {
          min-height: 50px;
          height: 50px;
          border-top: 1px dashed #C6D2D9;
          border-left: 1px dashed #C6D2D9;
          border-right: 0px;
          border-bottom: 0px;
  
        }
      }
  
      .rbc-time-header {
        .rbc-label {
          padding: 10px 0;
          /* Adjust padding to align with slot heights */
        }
      }
  
      .rbc-event {
        /* Adjust event styles if necessary */
        min-height: 30px;
        /* Ensure events are visible within the slot */
      }
  
      /* Optional: Adjust the overall calendar layout */
      .rbc-calendar {
        height: calc(100vh - 197px) !important;
  
        .rbc-header {
          font-size: 100%;
          height: 40px;
          background: #eaf6ff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          color: #000;
        }
  
        .rbc-toolbar {
          margin-top: 10px;
          justify-content: space-between;
  
          .rbc-toolbar-label {
            display: inline-block;
            flex-grow: unset;
            width: auto;
            order: 1;
            font-weight: 600;
            font-size: 20px;
            color: #313984;
            padding-left: 0;
          }
  
          .rbc-btn-group {
            order: 2;
            padding: 0 0 0 10px;
            display: flex;
  
            &:last-child {
              display: none;
            }
  
            button {
              margin: 0 5px;
              padding: 11px 41px;
  
              &:last-child {
                margin-right: 0;
              }
  
              &:first-child {
                order: 2;
  
                &:not(:last-child) {
                  cursor: pointer;
                  transition: 0.5s all;
                  color: #fff !important;
                  border-radius: 0 !important;
                  background: #81a3b8 !important;
                  background-color: #81a3b8 !important;
                  border: 1px solid #81a3b8 !important;
  
                  &:hover {
                    background: #648194 !important;
                    background-color: #648194 !important;
                  }
                }
              }
  
              &:not(:first-child) {
                order: 1;
  
                &:not(:last-child) {
                  cursor: pointer;
                  transition: 0.5s all;
                  color: #fff !important;
                  border-radius: 0 !important;
                  background: #313984 !important;
                  background-color: #313984 !important;
                  border: 1px solid #313984 !important;
  
                  &:hover {
                    background: #1f255a !important;
                    background-color: #1f255a !important;
                  }
                }
              }
  
              &:last-child {
                order: 3;
  
                &:not(:first-child) {
                  cursor: pointer;
                  transition: 0.5s all;
                  color: #fff !important;
                  border-radius: 0 !important;
                  background: #313984 !important;
                  background-color: #313984 !important;
                  border: 1px solid #313984 !important;
  
                  &:hover {
                    background: #1f255a !important;
                    background-color: #1f255a !important;
                  }
                }
              }
            }
  
          }
        }
  
        .rbc-day-bg {
          cursor: pointer;
        }
  
        .rbc-off-range-bg {
          cursor: not-allowed;
        }
      }
  
      #event-booking-modal {
        .main-model-sec {
          width: 35%;
  
          .event-view {
            padding: 0 15px 15px;
  
            .event-tile-date {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              margin-top: -40px;
              margin-right: 50px;
  
              .event-title {
                display: flex;
                align-items: flex-start;
  
                .fa {
                  padding-top: 5px;
                  margin-right: 25px;
                  color: #3174ad;
                }
  
                span {
                  font-size: 20px;
                  color: #3174ad;
                  font-weight: 600;
                }
              }
  
              .right-action {
                display: flex;
                align-items: center;
                padding-top: 7px;
  
                .left-part {
                  display: flex;
                  align-items: center;
                  .action-btn {
                    margin-right: 7px;
    
                    svg {
                      fill: #3174ad !important;
    
                      path {
                        fill: #3174ad !important;
                      }
                    }
                  }
                }
  
                .right-part {
                  .inner-btn-dropdown {
                    .custom-action-btn {
                      background: none;
                      border: none;
                      box-shadow: none;
                      height: auto;
                      width: 20px;
                      padding: 0;
                      position: relative;
                      margin-right: 0;
  
                      svg {
                        display: none;
                      }
  
                      &:before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 10px;
                        height: 40px;
                        transform: translate(-50%, -50%);
                        background: url(../../../public/option.png);
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                      }
                    }
  
                    .origin-top-right {
                      width: 150px;
                      top: 0;
                      bottom: unset;
                      right: 10px;
  
                      ul {
                        li {
                          label {
                            cursor: pointer;
  
                            span {
                              margin: 0;
                            }
                          }
                          &:hover{
                            background: #eaf6ff;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
  
            .event-desc {
              .icon-desc {
                margin-top: 15px;
                display: flex;
                align-items: flex-start;
  
                .fa {
                  width: 22px;
                  height: 22px;
                  padding-top: 3px;
                  font-size: 20px;
                  margin-right: 25px;
                  color: #3174ad;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
  
                .details {
                  p {
                    font-size: 14px;
                    color: #000;
                    margin: 0;
                    span{
                      font-weight: 600;
                    }
                  }
  
                }
              }
  
            }
          }
        }
      }
    }
  }